import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View, Dimensions, Platform, PixelRatio, ScrollView, Image, Linking } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { moderateScale, scale } from 'react-native-size-matters';
import { TouchableOpacity } from 'react-native-web';
import Swiper from 'react-native-web-swiper';
import Icon from "react-native-vector-icons/FontAwesome5"


export default function App() {

  const {
    width,
    height,
  } = Dimensions.get('window');


  return (
    <View style={styles.container}>
      {Dimensions.get('window').width <= 1000 ?
        <View style={stylesSW.swSubContainer}>
          <Text style={stylesSW.swTitleText}>
            PERFIL
          </Text>
          <Text style={stylesSW.swTitleDesciptionText}>
            PERFIL
          </Text>
          <View style={stylesSW.swDetailsView}>
          </View>
          <View style={stylesSW.swViewparent}>
            <Swiper
              containerStyle={styles.swSwiper}
              innerContainerStyle={{
                justifyContent: "center",
              }}
              from={0}
              minDistanceForAction={0.1}
              controlsProps={{
                dotActiveStyle: { backgroundColor: 'transparent', borderWidth: 0, borderColor: '#fff', backfaceVisibility: "hidden", opacity: 0 },
                dotsTouchable: false,
                DotComponent: ({ index, isActive, onPress }) => {
                  if (isActive) {
                    return <Text onPress={onPress}></Text>;
                  }
                  return <Text onPress={onPress}></Text>;
                },
                prevPos: 'left',
                nextPos: 'right',
                nextTitle: '>',
                nextTitleStyle: { color: 'red', fontSize: normalize(16), fontWeight: normalize(400), },
                PrevComponent: ({ onPress }) => (
                  <TouchableOpacity onPress={onPress}>
                    <Text style={{ color: 'red', fontSize: normalize(16), fontWeight: normalize(400), }}>
                      {'<'}
                    </Text>
                  </TouchableOpacity>
                ),
              }}
            >
              <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "rgba(20,20,200,0.3)", borderRadius: scale(10), marginHorizontal: 10 }}>
                <Text>Slide 1</Text>
              </View>
              <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "rgba(20,200,20,0.3)", borderRadius: scale(10), marginHorizontal: 10 }}>
                <Text>Slide 2</Text>
              </View>
              <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "rgba(200,20,20,0.3)", borderRadius: scale(10), marginHorizontal: 10 }}>
                <Text>Slide 3</Text>
              </View>
            </Swiper>
          </View>
        </View>
        :
        <View style={styles.subContainer}>
          <Text style={styles.titleText}>
            PERFIL
          </Text>
          <Text style={styles.titleDesciptionText}>
            Soy un desarrollador mobile.
          </Text>
          <View style={styles.viewparent}>

            <View style={styles.SwiperView}>
              <Text style={styles.titleSwiperAndDetailsText}>
                Apps desarrolladas
              </Text>
              <Swiper
                containerStyle={styles.Swiper}
                innerContainerStyle={{
                  justifyContent: "center",
                }}
                from={0}
                minDistanceForAction={0.1}
                controlsProps={{
                  dotActiveStyle: { backgroundColor: 'transparent', borderWidth: 0, borderColor: '#fff', backfaceVisibility: "hidden", opacity: 0 },
                  dotsTouchable: false,
                  DotComponent: ({ index, isActive, onPress }) => {
                    if (isActive) {
                      return <Text onPress={onPress}></Text>;
                    }
                    return <Text onPress={onPress}></Text>;
                  },
                  prevPos: 'left',
                  nextPos: 'right',
                  nextTitle: '>',
                  nextTitleStyle: { color: 'red', fontSize: normalize(8), fontWeight: normalize(100), },
                  PrevComponent: ({ onPress }) => (
                    <TouchableOpacity onPress={onPress}>
                      <Text style={{ color: 'red', fontSize: normalize(8), fontWeight: normalize(100), }}>
                        {'<'}
                      </Text>
                    </TouchableOpacity>
                  ),
                }}
              >
                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "transparent", borderRadius: scale(10), borderColor: "rgba(20,20,200,0.3)", borderWidth: 1.5, marginVertical: 10 }}>
                  <Image source={require('./src/app_ama.png')} style={styles.appsImage} />
                  <Text style={{ color: "black", paddingHorizontal: normalize(10),fontSize:normalize(3.8) }}>Es un proyecto de radio hecho para la radio emisora AMA 92.3 ubicada en Buenos Aires, Argentina.</Text>
                  <View style={{ marginTop: normalize(2), flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "transparent", marginHorizontal: 10, paddingTop: normalize(8) }}>
                    <Icon name='apple' size={normalize(6)} style={styles.iosImage} />
                    <Text style={{ color: "blue", textDecorationLine: "underline",fontSize:normalize(3.8) }} onPress={() => Linking.openURL("https://apps.apple.com/ar/app/ama-92-3/id1468333070")}>iOS link</Text>
                    <Text>       </Text>
                    <Icon name='android' size={normalize(6)} style={styles.androidImage} />
                    <Text style={{ color: "blue", textDecorationLine: "underline",fontSize:normalize(3.8) }} onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.theyouker.radioama")}>android link</Text>
                    {/*<Text>       </Text>
              <Icon name='link' size={normalize(5)} style={styles.androidImage}/>
            */}
                  </View>
                </View>
                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "transparent", borderRadius: scale(10), borderColor: "rgba(200,20,20,0.3)", borderWidth: 1.5, marginHorizontal: 10 }}>
                  <Image source={require('./src/app_ca.png')} style={styles.appsImage} />
                  <Text style={{ color: "black", paddingHorizontal: normalize(10),fontSize:normalize(3.8) }}>Es un proyecto que lista toda la información,links y redes sociales del Ministerio de Adolescentes de la Iglesia Catedral de la Fe ubicada en Buenos Aires, Argentina.</Text>
                  <View style={{ marginTop: normalize(2), flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "transparent", marginHorizontal: 10, paddingTop: normalize(2) }}>
                    <Icon name='android' size={normalize(6)} style={styles.androidImage} />
                    <Text style={{ color: "blue", textDecorationLine: "underline",fontSize:normalize(3.8) }} onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.theyouker.cadolescentes")}>android link</Text>
                  </View>
                </View>
                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "transparent", borderRadius: scale(10), borderColor: "rgba(20,200,20,0.3)", borderWidth: 1.5, marginHorizontal: 10 }}>
                  <Image source={require('./src/app_ecv.png')} style={styles.appsImage} />
                  <Text style={{ color: "black", paddingHorizontal: normalize(10),fontSize:normalize(3.8) }}>Es un proyecto que lista los recursos, información y links de la Iglesia Catedral de la Fe ubicada en Buenos Aires, Argentina.</Text>
                  <View style={{ marginTop: normalize(2), flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "transparent", marginHorizontal: 10, paddingTop: normalize(5) }}>
                    <Icon name='android' size={normalize(6)} style={styles.androidImage} />
                    <Text style={{ color: "blue", textDecorationLine: "underline",fontSize:normalize(3.8) }} onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.theyouker.estamosconvos")}>android link</Text>
                    <Text>       </Text>
                    <Icon name='link' size={normalize(5)} style={styles.webImage} />
                    <Text style={{ color: "blue", textDecorationLine: "underline", fontSize:normalize(3.8)}} onPress={() => Linking.openURL("https://app-estamos-con-vos.web.app/")}>web link</Text>

                  </View>
                </View>
              </Swiper>
            </View>
            <View style={styles.detailsView}>
              <View style={styles.profileView}>
                <Image source={require('./src/profile.jpg')} style={styles.profileImage} />
              </View>
              <View style={styles.dataView}>
              <Text style={styles.titleSwiperAndDetailsText}>
                Detalle
              </Text>
              <View style={styles.detailsDataView}>
                <Text style={styles.detailsTexts}>Nombre: Matías Domínguez</Text>
                <Text style={styles.detailsTexts}>Matías Domínguez</Text>
                <Text style={styles.detailsTexts}>Fecha de nacimiento:</Text>
                <Text style={styles.detailsTexts}>19/08/1999</Text>
                <Text style={styles.detailsTexts}>Ubicación:</Text>
                <Text style={styles.detailsTexts}>CABA,Buenos Aires, Argentina</Text>
                <Icon name='linkedin' size={normalize(6)} style={styles.linkedinImage} />
                </View>
              </View>
            </View>
          </View>
        </View>
      }

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#bdcbaf',
    justifyContent: 'center',
    alignItems: "center",
    paddingVertical: scale(10),

  },
  subContainer: {
    justifyContent: 'center',
    alignItems: "center",
    flex: 1,

  },
  viewparent: {
    width: normalize(300),
    height: normalize(100),
    backgroundColor: "white",
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: "center",
    borderRadius: normalize(7),
  },
  SwiperView: {
    width: normalize(90),
    height: normalize(90),
    backgroundColor: "transparent",
    ustifyContent: 'center',
    alignItems: "center",
  },
  Swiper: {
    width: normalize(90),
    height: normalize(70),
    backgroundColor: "white",
    justifyContent: 'center',
    alignItems: "center",
  },
  titleSwiperAndDetailsText: {
    fontSize: normalize(7),
    color: "black",
    paddingHorizontal: scale(10),
    marginBottom: scale(5),
    justifyContent: 'center',
    alignItems: "center",
  },
  titleText: {
    fontSize: normalize(20),
    color: "black",
    paddingHorizontal: scale(10),
    ustifyContent: 'center',
    alignItems: "center",
  },
  titleDesciptionText: {
    fontSize: normalize(6),
    color: "black",
    paddingHorizontal: scale(10),
    marginBottom: scale(10),
    justifyContent: 'center',
    alignItems: "center",
    
  },
  detailsView: {
    width: normalize(200),
    height: normalize(100),
    backgroundColor: "white",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: normalize(7),
    marginRight: normalize(2)
  },
  profileView: {
    width: normalize(100),
    height: normalize(100),
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: 'center',
  },
  profileImage: {
    width: normalize(65),
    height: normalize(65),
    borderRadius: normalize(75),
    margin: normalize(2)
  },
  dataView: {
    width: normalize(90),
    height: normalize(90),
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
    margin: normalize(2),
    padding: normalize(2),
  },
  detailsDataView: {
    width: normalize(90),
    height: normalize(77),
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  detailsTexts:{
    marginVertical:normalize(2),
    fontSize:normalize(3.8)
  },
  appsImage: {
    width: normalize(30),
    height: normalize(30),
    borderRadius: normalize(30),
    margin: normalize(2),
  },
  iosImage: {
    width: normalize(8),
    height: normalize(8),
  },
  androidImage: {
    width: normalize(8),
    height: normalize(8),
    color: "green"
  },
  webImage: {
    width: normalize(8),
    height: normalize(8),
  },
  linkedinImage: {
    marginTop:normalize(8),
    width: normalize(6),
    height: normalize(6),
    color:"darkblue"
  },
});
const stylesSW = StyleSheet.create({

  swSubContainer: {
    justifyContent: 'center',
    alignItems: "center",

  },
  swViewparent: {
    width: normalize(380),
    height: normalize(300),
    backgroundColor: "white",
    flexDirection: "row"
  },
  swDetailsView: {
    width: normalize(380),
    height: normalize(300),
    backgroundColor: "white",
  },
  swSwiper: {
    margin: normalize(10),
    width: normalize(220),
    height: normalize(280),
    backgroundColor: "white",
  },
  swTitleText: {
    fontSize: normalize(40),
    color: "white",
    paddingHorizontal: scale(10),
  },
  swTitleDesciptionText: {
    fontSize: normalize(20),
    color: "white",
    paddingHorizontal: scale(10),
    marginBottom: scale(10),
  },
});
function normalize(size) {
  const multiplier = 2;
  const scale = (Dimensions.get('window').width / Dimensions.get('window').height) * multiplier;

  const newSize = size * scale;

  return Math.round(PixelRatio.roundToNearestPixel(newSize));
}